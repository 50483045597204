import React from 'react';
import { Box, Spinner } from '@chakra-ui/react';

export const SpinnerOverlay: React.FC<{ isLoaded: boolean }> = ({ isLoaded, children }) => {
    return (
        <Box position="relative">
            {!isLoaded && (
                <Box
                    position="absolute"
                    top={0}
                    left={0}
                    w="100%"
                    h="100%"
                    display="flex"
                    alignItems="center"
                    justifyItems="center"
                    textAlign="center"
                    opacity="0.6"
                    background="white"
                    zIndex={1}>
                    <Box flexGrow={1}>
                        <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="lg" />
                    </Box>
                </Box>
            )}

            {children}
        </Box>
    );
};
