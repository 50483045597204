import React, { useRef, useState } from 'react';
import throttle from 'lodash.throttle';
import { Box } from '@chakra-ui/react';
import { LEFT, RIGHT, useSwipeable } from 'react-swipeable';

const VELOCITY_THRESHOLD = 0.8;
const POSITION_CONSTRAINT = 150;
const ANIMATION_TIME = 250;

const PrevNextContainer: React.FC<{ direction: 'left' | 'right' }> = ({ children, direction }) => {
    const posProps = direction === 'left' ? { left: '0' } : { right: '0' };
    return (
        <Box position="absolute" top="47%" {...posProps}>
            {children}
        </Box>
    );
};

const CardContainer = ({
    children,
    headerRenderer,
    footerRenderer,
    colors,
    setIsDragging,
    setHasTransition,
    velocityThreshold,
    constraints,
    swipeable,
    transition,
    onSwipeLeft,
    onSwipeRight,
    borderStyle,
}: {
    children?: React.ReactNode;
    headerRenderer?: () => React.ReactNode;
    footerRenderer?: () => React.ReactNode;
    colors?: {
        border?: string;
        text?: string;
        background?: string;
    };
    onSwipeLeft?: () => void;
    onSwipeRight?: () => void;
    setIsDragging?: (isDragging: boolean) => void;
    setHasTransition?: (isDragging: boolean) => void;
    velocityThreshold?: number;
    constraints?: {
        top?: number;
        left?: number;
    };
    swipeable?: boolean;
    transition?: string;
    borderStyle?: string;
}) => {
    const borderColor = colors?.border ?? 'card.border';
    const questionColor = colors?.text ?? 'card.text';
    const backgroundColor = colors?.background ?? 'card.background';
    const rowTemplate = `${headerRenderer ? '2em ' : ''} 1fr${footerRenderer ? ' 2em ' : ''}`;

    const [position, setPosition] = useState<{ top: number | string; left: number | string }>({ top: 0, left: 0 });
    const threshold = velocityThreshold ?? VELOCITY_THRESHOLD;
    const constraintTop = constraints?.top ?? POSITION_CONSTRAINT;
    const constraintLeft = constraints?.left ?? POSITION_CONSTRAINT;

    const resetState = useRef(() => {
        return new Promise<void>((resolve) => {
            setTimeout(() => {
                setIsDragging?.(false);
                setHasTransition?.(true);
                resolve();
            }, 1);
        });
    });

    const moveCard = useRef(
        throttle(
            ({ top, left }: { top: number | string; left: number | string }) => {
                setPosition({ top, left });
            },
            100,
            { leading: true },
        ),
    );

    const swipeCard = useRef((direction: typeof LEFT | typeof RIGHT) => {
        moveCard.current({ top: 0, left: direction === LEFT ? -2000 : 2000 });
        return new Promise<void>((resolve) => {
            setTimeout(() => {
                setHasTransition?.(false);
                resolve();
            }, ANIMATION_TIME / 2);
        });
    });

    const swipeHandlers = useSwipeable({
        onSwipedLeft: async (eventData) => {
            if (eventData.velocity > threshold && onSwipeLeft) {
                await swipeCard.current(LEFT);
                onSwipeLeft();
                await swipeCard.current(RIGHT);
                await resetState.current();
                setPosition({ top: 0, left: 0 });
            } else {
                moveCard.current({ top: 0, left: 0 });
            }
        },
        onSwipedRight: async (eventData) => {
            if (eventData.velocity > threshold && onSwipeRight) {
                await swipeCard.current(RIGHT);
                onSwipeRight();
                await swipeCard.current(LEFT);
                await resetState.current();
                setPosition({ top: 0, left: 0 });
            } else {
                moveCard.current({ top: 0, left: 0 });
            }
        },
        onSwipedDown: () => {
            moveCard.current({ top: 0, left: 0 });
        },
        onSwipedUp: () => {
            moveCard.current({ top: 0, left: 0 });
        },
        onSwiping: (eventData) => {
            const left =
                eventData.deltaX > constraintLeft
                    ? constraintLeft
                    : eventData.deltaX < -constraintLeft
                    ? -constraintLeft
                    : eventData.deltaX;
            const top =
                eventData.deltaY > constraintTop
                    ? constraintTop
                    : eventData.deltaY < -constraintTop
                    ? -constraintTop
                    : eventData.deltaY;
            moveCard.current({ top: `${top}px`, left: `${left}px` });
        },
        onSwipeStart: () => setIsDragging?.(true),
        onSwiped: () => {
            setIsDragging?.(false);
        },
        trackMouse: true,
        trackTouch: true,
        preventDefaultTouchmoveEvent: true,
    });

    return (
        <Box
            {...(swipeable ? swipeHandlers : {})}
            paddingTop={6}
            paddingBottom={6}
            paddingLeft={8}
            paddingRight={8}
            borderColor={borderColor}
            borderRadius="lg"
            borderWidth={1}
            backgroundColor={backgroundColor}
            height="100%"
            width="100%"
            display="grid"
            gridTemplateRows={rowTemplate}
            gridGap={4}
            borderStyle={borderStyle}
            transition={swipeable ? transition : undefined}
            top={swipeable ? position.top : undefined}
            left={swipeable ? position.left : undefined}
            position={swipeable ? 'absolute' : 'relative'}>
            {!!headerRenderer && <Box alignSelf="flex-start">{headerRenderer()}</Box>}
            <Box
                fontWeight="bold"
                fontSize={['2xl', '3xl']}
                color={questionColor}
                alignSelf="center"
                textAlign="center">
                {children}
            </Box>
            {!!footerRenderer && <Box alignSelf="flex-end">{footerRenderer()}</Box>}
        </Box>
    );
};

export const QuestionCard = (props: {
    children?: React.ReactNode;
    swipeable?: boolean;
    onSwipeLeft?: () => void;
    onSwipeRight?: () => void;
    headerRenderer?: () => React.ReactNode;
    footerRenderer?: () => React.ReactNode;
    previousRenderer?: () => React.ReactNode;
    nextRenderer?: () => React.ReactNode;
    colors?: {
        border?: string;
        text?: string;
    };
    velocityThreshold?: number;
    constraints?: {
        top?: number;
        left?: number;
    };
}) => {
    const [isDragging, setIsDragging] = useState(false);
    const [hasTransistion, setHasTransition] = useState(true);

    return (
        <Box position="relative" height="70vh">
            {props.swipeable && (
                <CardContainer
                    colors={{ text: 'base.400', border: 'base.400', background: 'base.200' }}
                    borderStyle={'dashed'}>
                    Questionable
                </CardContainer>
            )}

            <CardContainer
                swipeable={props.swipeable}
                onSwipeRight={props.onSwipeRight}
                onSwipeLeft={props.onSwipeLeft}
                footerRenderer={props.footerRenderer}
                headerRenderer={props.headerRenderer}
                setHasTransition={setHasTransition}
                setIsDragging={setIsDragging}
                transition={
                    hasTransistion
                        ? `top ${ANIMATION_TIME}ms ease, left ${ANIMATION_TIME}ms ease, opacity ${ANIMATION_TIME}ms ease`
                        : undefined
                }>
                {props.children}
            </CardContainer>
            {!!props.previousRenderer && !isDragging && (
                <PrevNextContainer direction="left">{props.previousRenderer()}</PrevNextContainer>
            )}
            {!!props.nextRenderer && !isDragging && (
                <PrevNextContainer direction="right">{props.nextRenderer()}</PrevNextContainer>
            )}
        </Box>
    );
};
