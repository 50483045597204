import React from 'react';
import { Box } from '@chakra-ui/react';

export const Frame: React.FC = (props) => {
    return (
        <Box p={8} maxW={['480px', null, '600px', null]} m="auto">
            {props.children}
        </Box>
    );
};
