import React from 'react';
import { ButtonWithPopover, ButtonWithPopoverProps } from './ButtonWithPopover';
import { Icon, IconButton } from '@chakra-ui/react';

import { ReactComponent as Bin } from '@streamlinehq/streamlinehq/img/streamline-regular/interface-essential/delete/bin.svg';
import { ReactComponent as Bin2 } from '@streamlinehq/streamlinehq/img/streamline-regular/interface-essential/delete/bin-2.svg';

export const DeleteButtonWithPopover: React.FC<{ disabled?: boolean; title?: string } & ButtonWithPopoverProps> = ({
    disabled,
    title,
    popoverTitle,
    onClose,
    onConfirm,
    popoverContent,
    confirmText,
}) => {
    const [hoverDelete, setHoverDelete] = React.useState<boolean>(false);

    return (
        <ButtonWithPopover
            popoverTitle={popoverTitle}
            onClose={onClose}
            onConfirm={onConfirm}
            popoverContent={popoverContent}
            confirmText={confirmText}>
            <IconButton
                size="sm"
                icon={<Icon>{hoverDelete && !disabled ? <Bin2 /> : <Bin />}</Icon>}
                title={title ?? ''}
                aria-label={title ?? ''}
                colorScheme="red"
                onMouseEnter={() => setHoverDelete(true)}
                onMouseLeave={() => setHoverDelete(false)}
                disabled={disabled}
            />
        </ButtonWithPopover>
    );
};
