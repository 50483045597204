import React from 'react';
import { Box, Checkbox, Icon, IconButton } from '@chakra-ui/react';
import { RowItem } from './RowItem';

import { ButtonWithPopover } from './ButtonWithPopover';
import { DeleteButtonWithPopover } from './DeleteButtonWithPopover';
import { ReactComponent as TaskListAdd1 } from '@streamlinehq/streamlinehq/img/streamline-regular/work-office-companies/tasks/task-list-add-1.svg';
import { ReactComponent as TaskListDelete } from '@streamlinehq/streamlinehq/img/streamline-regular/work-office-companies/tasks/task-list-delete.svg';

export const QuestionListHeader: React.FC<{
    isAllChecked: boolean;
    onCheckAllClick: (checked: boolean) => void;
    enableActions: boolean;
    showCollectionActions: boolean;
    onDeleteAllClick?: () => Promise<void>;
    onAddToCollectionClick?: () => Promise<void>;
    onRemoveFromCollectionClick?: () => Promise<void>;
}> = ({
    isAllChecked,
    onCheckAllClick,
    enableActions,
    showCollectionActions,
    onDeleteAllClick,
    onRemoveFromCollectionClick,
    onAddToCollectionClick,
}) => {
    return (
        <RowItem noBorder>
            <Box gridColumn="1" gridRow="1 / span 2" display="flex" alignItems="center">
                <Checkbox
                    value={'all'}
                    isChecked={isAllChecked}
                    onChange={(e) => {
                        onCheckAllClick(e.target.checked);
                    }}
                />
            </Box>
            <Box justifySelf="flex-end" gridRow="1 / span 2" gridColumn="3" display="flex" gridGap={1}>
                {showCollectionActions ? (
                    <>
                        <ButtonWithPopover
                            popoverTitle="Remove Questions from Collection?"
                            confirmText="Remove Questions"
                            popoverContent="This does not delete the Questions"
                            onConfirm={async () => {
                                return onRemoveFromCollectionClick?.();
                            }}>
                            <IconButton
                                size="sm"
                                icon={
                                    <Icon>
                                        <TaskListDelete />
                                    </Icon>
                                }
                                title="Remove Questions from Collection"
                                aria-label="remove question from collection"
                                disabled={!enableActions}
                            />
                        </ButtonWithPopover>
                    </>
                ) : (
                    <IconButton
                        size="sm"
                        icon={
                            <Icon>
                                <TaskListAdd1 />
                            </Icon>
                        }
                        title="Add Selected Questions to Collection"
                        aria-label="add selected questions to collection"
                        onClick={async () => onAddToCollectionClick?.()}
                        disabled={!enableActions}
                    />
                )}
                <DeleteButtonWithPopover
                    title="Delete Selected Questions"
                    popoverTitle="Delete Questions?"
                    popoverContent="This will delete the questions from all collections they're associated to."
                    confirmText="Delete Selected Questions"
                    onConfirm={async () => {
                        await onDeleteAllClick?.();
                    }}
                    disabled={!enableActions}
                />
            </Box>
        </RowItem>
    );
};
