import React, { useCallback, useEffect } from 'react';
import { CollectionReturnGetCollectionWithTags } from 'src/types/Collection';
import { Unpack } from 'src/types/Unpack';

type Tags = NonNullable<CollectionReturnGetCollectionWithTags>['tags'];

export const useLocalTags = (originalTags: Tags) => {
    const sanitizeTag = (tag: Unpack<Tags>) => ({
        id: tag.id,
        name: tag.name,
    });
    const sanitizeTags = useCallback((tagsToSanitize?: Tags) => (tagsToSanitize ?? []).map(sanitizeTag), []);

    const [tags, setTags] = React.useState<{ name: string; id: string }[]>(sanitizeTags(originalTags));

    const tagsStr = JSON.stringify((originalTags ?? []).map((tag) => tag.id).sort());

    const setSanitizedTags = useCallback(
        (tagsToSanitize: Tags) => {
            setTags(sanitizeTags(tagsToSanitize));
        },
        [sanitizeTags],
    );

    useEffect(() => {
        setTags(sanitizeTags(originalTags));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tagsStr, sanitizeTags]);

    return [tags, setSanitizedTags] as const;
};
