import React from 'react';
import {
    Box,
    Button,
    Checkbox,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
} from '@chakra-ui/react';
import { CollectionReturnGetCollectionWithTags } from 'src/types/Collection';
import { Field, FieldProps, Form, Formik } from 'formik';
import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/form-control';
import { ListTags } from './ListTags';
import { useLocalTags } from 'src/hooks/useLocalTags';

export const CollectionDetails: React.FC<{
    isOpen: boolean;
    onClose: () => void;
    onSave: (values: {
        id?: string;
        name: string;
        orderEnforced?: boolean;
        tags: { id: string; name: string }[];
    }) => Promise<void>;
    collection: CollectionReturnGetCollectionWithTags;
}> = ({ isOpen, collection, onClose, onSave }) => {
    const [tags, setTags] = useLocalTags(collection?.tags ?? []);

    return (
        <Modal
            isOpen={isOpen}
            onClose={() => {
                setTags(collection?.tags ?? []);
                onClose();
            }}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{collection?.name ?? 'Create new collection'}</ModalHeader>
                <ModalCloseButton />
                <Formik
                    initialValues={{
                        name: collection?.name ?? '',
                    }}
                    onSubmit={async (values, { setSubmitting }) => {
                        const valuesToSave = {
                            ...values,
                            tags,
                            id: collection?.id,
                        };
                        await onSave(valuesToSave);
                        setSubmitting(false);
                        setTags([]);
                        onClose();
                    }}>
                    {({ isSubmitting }) => (
                        <Form>
                            <ModalBody>
                                <Field
                                    name="name"
                                    validate={(value?: string) => {
                                        if (!value) {
                                            return 'Please enter a name';
                                        }
                                        return undefined;
                                    }}>
                                    {({
                                        field,
                                        form,
                                    }: FieldProps<string, { name: string; orderEnforced: boolean }>) => (
                                        <FormControl isInvalid={!!form.errors.name && form.touched.name}>
                                            <FormLabel htmlFor="name">Collection name</FormLabel>
                                            <Input {...field} id="name" />
                                            <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                                        </FormControl>
                                    )}
                                </Field>

                                <Field name="orderEnforced">
                                    {({ field }: FieldProps<string>) => (
                                        <FormControl>
                                            <FormLabel htmlFor="orderEnforced">Enforce question order</FormLabel>
                                            <Checkbox
                                                defaultChecked={collection?.orderEnforced}
                                                isChecked={field.checked}
                                                value="true"
                                                id="orderEnforced"
                                                onChange={(e) => {
                                                    field.onChange(e);
                                                }}
                                            />
                                        </FormControl>
                                    )}
                                </Field>

                                <Box>
                                    <ListTags
                                        tags={tags}
                                        onTagRemove={(tagId) => {
                                            setTags(tags.filter((tag) => tag.id !== tagId));
                                        }}
                                        onTagAdd={(tag) => {
                                            setTags([...tags, tag]);
                                        }}
                                    />
                                </Box>
                            </ModalBody>
                            <ModalFooter>
                                <Button mt={4} isLoading={isSubmitting} type="submit">
                                    Submit
                                </Button>
                            </ModalFooter>
                        </Form>
                    )}
                </Formik>
            </ModalContent>
        </Modal>
    );
};
