import React from 'react';
import {
    Box,
    Button,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Textarea,
} from '@chakra-ui/react';
import { Field, FieldProps, Form, Formik } from 'formik';
import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/form-control';
import { ListTags } from './ListTags';
import { QuestionReturnListQuestions } from 'src/types/Question';
import { useLocalTags } from 'src/hooks/useLocalTags';

export const QuestionDetails = (props: {
    isOpen: boolean;
    onClose: () => void;
    selectedQuestion?: QuestionReturnListQuestions;
    collectionId?: string;
    onSave: (values: {
        id?: string;
        tags: { name: string; id: string }[];
        questions: string[] | string;
    }) => Promise<void>;
}) => {
    const { isOpen, onClose, selectedQuestion, onSave } = props;
    const [tags, setTags] = useLocalTags(selectedQuestion?.tags ?? []);

    return (
        <Modal
            isOpen={isOpen}
            onClose={() => {
                onClose();
            }}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{selectedQuestion ? 'Edit question' : 'Add question(s)'}</ModalHeader>
                <ModalCloseButton />
                <Formik
                    initialValues={{
                        text: selectedQuestion?.text ?? '',
                    }}
                    enableReinitialize
                    onSubmit={async (values, { setSubmitting }) => {
                        const questions = selectedQuestion
                            ? values.text
                            : values.text
                                  .split('\n')
                                  .map((text) => text.trim())
                                  .filter((text) => !!text);
                        const valuesToSave = {
                            questions,
                            tags,
                            id: selectedQuestion?.id,
                        };
                        await onSave(valuesToSave);
                        setSubmitting(false);
                        onClose();
                    }}>
                    {({ isSubmitting }) => (
                        <Form>
                            <ModalBody>
                                <Field
                                    name="text"
                                    validate={(value?: string) => {
                                        if (!value) {
                                            return 'Please enter the question text';
                                        }
                                        return undefined;
                                    }}>
                                    {({ field, form }: FieldProps<string, { text: string }>) => (
                                        <FormControl isInvalid={!!form.errors.text && form.touched.text}>
                                            <FormLabel htmlFor="text">Question text</FormLabel>
                                            {selectedQuestion ? (
                                                <Input {...field} id="text" />
                                            ) : (
                                                <Textarea {...field} id="text" />
                                            )}
                                            <FormErrorMessage>{form.errors.text}</FormErrorMessage>
                                        </FormControl>
                                    )}
                                </Field>
                                <Box>
                                    <ListTags
                                        tags={tags}
                                        onTagRemove={(tagId) => {
                                            setTags(tags.filter((tag) => tag.id !== tagId));
                                        }}
                                        onTagAdd={(tag) => {
                                            setTags([...tags, tag]);
                                        }}
                                    />
                                </Box>
                            </ModalBody>
                            <ModalFooter>
                                <Button mt={4} isLoading={isSubmitting} type="submit">
                                    {selectedQuestion ? 'Update Question' : 'Create Question(s)'}
                                </Button>
                            </ModalFooter>
                        </Form>
                    )}
                </Formik>
            </ModalContent>
        </Modal>
    );
};
