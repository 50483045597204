import React, { useEffect } from 'react';
import ReactSelect from 'react-select';
import {
    Button,
    List,
    ListItem,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Skeleton,
} from '@chakra-ui/react';
import { QuestionItem } from './QuestionItem';
import { QuestionListHeader } from './QuestionListHeader';
import { QuestionReturnListQuestions } from 'src/types/Question';
import { SpinnerOverlay } from './SpinnerOverlay';
import { useListCollectionsQuery, useMoveQuestionMutation } from 'src/generated/graphql';

export const QuestionList = (props: {
    collectionId?: string;
    questions: QuestionReturnListQuestions[];
    onEditClick?: (questionId: string) => void;
    onDeleteClick?: (questionIds: string[]) => Promise<void>;
    orderEnforced?: boolean;
    onAddToCollectionClick?: (questionIds: string[], collectionId: string) => Promise<void>;
    onRemoveFromCollectionClick?: (questionIds: string[], collectionId: string) => Promise<void>;
    onMoveQuestion?: () => Promise<void>;
}) => {
    const [selectedQuestions, setSelectedQuestions] = React.useState<string[]>([]);
    const [isAddingToCollection, setIsAddingToCollection] = React.useState<boolean>(false);
    const [isAddToCollectionOpen, setIsAddToCollectionOpen] = React.useState<boolean>(false);
    const [selectedCollection, setSelectedCollection] = React.useState<string | undefined>();
    const {
        collectionId,
        questions,
        onEditClick,
        onDeleteClick,
        onAddToCollectionClick,
        onRemoveFromCollectionClick,
        onMoveQuestion,
    } = props;
    const collectionsResults = useListCollectionsQuery();
    const [moveQuestion, moveQuestionResults] = useMoveQuestionMutation();

    useEffect(() => {
        setSelectedQuestions([]);
    }, [collectionId]);

    const questionList = collectionId
        ? [...questions].sort((questionA, questionB) => {
              const collectionA = questionA.collections.find((collection) => collection.collection.id === collectionId);
              const collectionB = questionB.collections.find((collection) => collection.collection.id === collectionId);
              return (collectionA?.order ?? 0) - (collectionB?.order ?? 0);
          })
        : questions;

    return (
        <>
            <SpinnerOverlay isLoaded={!moveQuestionResults.loading}>
                <List display="grid" gridGap={2}>
                    <QuestionListHeader
                        enableActions={selectedQuestions.length > 0}
                        showCollectionActions={!!collectionId}
                        isAllChecked={!!questions.length && questions.length === selectedQuestions.length}
                        onCheckAllClick={(checked) =>
                            setSelectedQuestions(checked ? questions.map((question) => question.id) ?? [] : [])
                        }
                        onDeleteAllClick={async () => onDeleteClick?.(selectedQuestions)}
                        onAddToCollectionClick={async () => {
                            setIsAddToCollectionOpen(true);
                        }}
                        onRemoveFromCollectionClick={async () => {
                            if (collectionId) {
                                return onRemoveFromCollectionClick?.(selectedQuestions, collectionId);
                            }
                        }}
                    />

                    {questionList.map((question) => {
                        const collectionItem = question.collections.find(
                            (collection) => collection.collection.id === collectionId,
                        );
                        return (
                            <ListItem key={question.id}>
                                <QuestionItem
                                    question={question}
                                    collectionId={collectionId}
                                    checked={selectedQuestions.includes(question.id)}
                                    onEditClick={onEditClick}
                                    onDeleteClick={async (id) => onDeleteClick?.([id])}
                                    order={collectionItem?.order}
                                    isLast={collectionItem?.order === questionList.length - 1}
                                    orderEnforced={props.orderEnforced}
                                    onQuestionCheck={(id, checked) => {
                                        setSelectedQuestions(
                                            checked
                                                ? [...selectedQuestions, id]
                                                : selectedQuestions.filter((selected) => selected !== id),
                                        );
                                    }}
                                    onAddToCollectionClick={async (ids) => {
                                        setIsAddToCollectionOpen(true);
                                        setSelectedQuestions(ids);
                                    }}
                                    onRemoveFromCollectionClick={onRemoveFromCollectionClick}
                                    onMoveQuestionClick={async (questionId, direction) => {
                                        if (collectionId) {
                                            await moveQuestion({
                                                variables: { id: questionId, collectionId, direction },
                                            });
                                            await onMoveQuestion?.();
                                        }
                                    }}
                                />
                            </ListItem>
                        );
                    })}
                </List>
            </SpinnerOverlay>
            <Modal
                isOpen={isAddToCollectionOpen}
                onClose={() => {
                    setIsAddToCollectionOpen(false);
                    setIsAddingToCollection(false);
                    setSelectedCollection(undefined);
                }}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{'Choose a collection to add questions to'}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Skeleton isLoaded={!collectionsResults.loading}>
                            <ReactSelect
                                options={collectionsResults.data?.collections.map((collection) => ({
                                    label: collection.name,
                                    value: collection.id,
                                }))}
                                onChange={(option) => {
                                    setSelectedCollection(option?.value);
                                }}
                            />
                        </Skeleton>
                        <Button
                            isLoading={isAddingToCollection}
                            onClick={async () => {
                                setIsAddingToCollection(true);
                                if (selectedCollection) {
                                    await onAddToCollectionClick?.(selectedQuestions, selectedCollection);
                                }
                                setIsAddingToCollection(false);
                                setIsAddToCollectionOpen(false);
                                setSelectedCollection(undefined);
                            }}>
                            Add to Collection
                        </Button>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};
