/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
};

export type Query = {
  __typename?: 'Query';
  collections: Array<Collection>;
  collection?: Maybe<Collection>;
  questions: Array<Question>;
  question?: Maybe<Question>;
  qotd?: Maybe<Question>;
  tags: Array<Tag>;
};


export type QueryCollectionsArgs = {
  offset?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
};


export type QueryCollectionArgs = {
  id: Scalars['String'];
};


export type QueryQuestionsArgs = {
  offset?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
  collectionId?: Maybe<Scalars['String']>;
  filter?: Maybe<Scalars['String']>;
};


export type QueryQuestionArgs = {
  id: Scalars['String'];
};


export type QueryQotdArgs = {
  collectionIds?: Maybe<Scalars['String']>;
};


export type QueryTagsArgs = {
  offset?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
  filter?: Maybe<Scalars['String']>;
};

export type Collection = {
  __typename?: 'Collection';
  id: Scalars['String'];
  name: Scalars['String'];
  questions: Array<QuestionInCollection>;
  tags: Array<Tag>;
  orderEnforced: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  questionCount: Scalars['Int'];
};

export type QuestionInCollection = {
  __typename?: 'QuestionInCollection';
  id: Scalars['String'];
  collection: Collection;
  collectionId: Scalars['String'];
  question: Question;
  questionId: Scalars['String'];
  order: Scalars['Float'];
};

export type Question = {
  __typename?: 'Question';
  id: Scalars['String'];
  text: Scalars['String'];
  collections: Array<QuestionInCollection>;
  tags: Array<Tag>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type Tag = {
  __typename?: 'Tag';
  id: Scalars['String'];
  name: Scalars['String'];
  collections: Array<Collection>;
  questions: Array<Question>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};


export type Mutation = {
  __typename?: 'Mutation';
  mutateCollection?: Maybe<CollectionResponse>;
  deleteCollection?: Maybe<CollectionDeleteResponse>;
  addQuestions?: Maybe<CollectionAddRemoveQuestionResponse>;
  removeQuestions?: Maybe<CollectionAddRemoveQuestionResponse>;
  mutateQuestion?: Maybe<QuestionResponse>;
  createQuestions?: Maybe<QuestionsResponse>;
  deleteQuestions?: Maybe<QuestionDeleteResponse>;
  moveQuestion?: Maybe<QuestionChangeResponse>;
  createTag: TagResponse;
};


export type MutationMutateCollectionArgs = {
  id?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<TagInput>>;
  orderEnforced?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
};


export type MutationDeleteCollectionArgs = {
  deleteQuestions?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
};


export type MutationAddQuestionsArgs = {
  questionIds: Array<Scalars['String']>;
  id: Scalars['String'];
};


export type MutationRemoveQuestionsArgs = {
  questionIds: Array<Scalars['String']>;
  id: Scalars['String'];
};


export type MutationMutateQuestionArgs = {
  id?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<TagInput>>;
  order?: Maybe<Scalars['Float']>;
  collectionId?: Maybe<Scalars['String']>;
  text: Scalars['String'];
};


export type MutationCreateQuestionsArgs = {
  tags?: Maybe<Array<TagInput>>;
  collectionId?: Maybe<Scalars['String']>;
  questions: Array<Scalars['String']>;
};


export type MutationDeleteQuestionsArgs = {
  ids: Array<Scalars['String']>;
};


export type MutationMoveQuestionArgs = {
  direction: Direction;
  collectionId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationCreateTagArgs = {
  name: Scalars['String'];
};

export type CollectionResponse = {
  __typename?: 'CollectionResponse';
  errors?: Maybe<Array<FieldError>>;
  collection?: Maybe<Collection>;
};

export type FieldError = {
  __typename?: 'FieldError';
  message: Scalars['String'];
  field?: Maybe<Scalars['String']>;
};

export type TagInput = {
  name: Scalars['String'];
  id: Scalars['String'];
};

export type CollectionDeleteResponse = {
  __typename?: 'CollectionDeleteResponse';
  errors?: Maybe<Array<FieldError>>;
  deletedIds?: Maybe<Array<Scalars['String']>>;
  deletedQuestionIds?: Maybe<Array<Scalars['String']>>;
};

export type CollectionAddRemoveQuestionResponse = {
  __typename?: 'CollectionAddRemoveQuestionResponse';
  errors?: Maybe<Array<FieldError>>;
  questionIds?: Maybe<Array<Scalars['String']>>;
};

export type QuestionResponse = {
  __typename?: 'QuestionResponse';
  errors?: Maybe<Array<FieldError>>;
  question?: Maybe<Question>;
};

export type QuestionsResponse = {
  __typename?: 'QuestionsResponse';
  errors?: Maybe<Array<FieldError>>;
  questions?: Maybe<Array<Question>>;
};

export type QuestionDeleteResponse = {
  __typename?: 'QuestionDeleteResponse';
  errors?: Maybe<Array<FieldError>>;
  deletedIds?: Maybe<Array<Scalars['String']>>;
};

export type QuestionChangeResponse = {
  __typename?: 'QuestionChangeResponse';
  errors?: Maybe<Array<FieldError>>;
  questionId: Scalars['String'];
  collectionId: Scalars['String'];
  newOrder: Scalars['Float'];
  oldOrder: Scalars['Float'];
};

export enum Direction {
  Up = 'UP',
  Down = 'DOWN'
}

export type TagResponse = {
  __typename?: 'TagResponse';
  errors?: Maybe<Array<FieldError>>;
  tag?: Maybe<Tag>;
};

export type AddQuestionsToCollectionMutationVariables = Exact<{
  id: Scalars['String'];
  questionIds: Array<Scalars['String']> | Scalars['String'];
}>;


export type AddQuestionsToCollectionMutation = (
  { __typename?: 'Mutation' }
  & { addQuestions?: Maybe<(
    { __typename?: 'CollectionAddRemoveQuestionResponse' }
    & Pick<CollectionAddRemoveQuestionResponse, 'questionIds'>
    & { errors?: Maybe<Array<(
      { __typename?: 'FieldError' }
      & Pick<FieldError, 'field' | 'message'>
    )>> }
  )> }
);

export type CreateQuestionsMutationVariables = Exact<{
  questions: Array<Scalars['String']> | Scalars['String'];
  collectionId?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<TagInput> | TagInput>;
}>;


export type CreateQuestionsMutation = (
  { __typename?: 'Mutation' }
  & { createQuestions?: Maybe<(
    { __typename?: 'QuestionsResponse' }
    & { errors?: Maybe<Array<(
      { __typename?: 'FieldError' }
      & Pick<FieldError, 'field' | 'message'>
    )>>, questions?: Maybe<Array<(
      { __typename?: 'Question' }
      & Pick<Question, 'id' | 'text'>
      & { collections: Array<(
        { __typename?: 'QuestionInCollection' }
        & Pick<QuestionInCollection, 'order'>
        & { collection: (
          { __typename?: 'Collection' }
          & Pick<Collection, 'id' | 'name' | 'orderEnforced'>
          & { tags: Array<(
            { __typename?: 'Tag' }
            & Pick<Tag, 'id' | 'name'>
          )> }
        ) }
      )> }
    )>> }
  )> }
);

export type CreateTagMutationVariables = Exact<{
  name: Scalars['String'];
}>;


export type CreateTagMutation = (
  { __typename?: 'Mutation' }
  & { createTag: (
    { __typename?: 'TagResponse' }
    & { errors?: Maybe<Array<(
      { __typename?: 'FieldError' }
      & Pick<FieldError, 'field' | 'message'>
    )>>, tag?: Maybe<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'name'>
    )> }
  ) }
);

export type DeleteCollectionMutationVariables = Exact<{
  id: Scalars['String'];
  deleteQuestions?: Maybe<Scalars['Boolean']>;
}>;


export type DeleteCollectionMutation = (
  { __typename?: 'Mutation' }
  & { deleteCollection?: Maybe<(
    { __typename?: 'CollectionDeleteResponse' }
    & Pick<CollectionDeleteResponse, 'deletedIds'>
    & { errors?: Maybe<Array<(
      { __typename?: 'FieldError' }
      & Pick<FieldError, 'field' | 'message'>
    )>> }
  )> }
);

export type DeleteQuestionsMutationVariables = Exact<{
  ids: Array<Scalars['String']> | Scalars['String'];
}>;


export type DeleteQuestionsMutation = (
  { __typename?: 'Mutation' }
  & { deleteQuestions?: Maybe<(
    { __typename?: 'QuestionDeleteResponse' }
    & Pick<QuestionDeleteResponse, 'deletedIds'>
    & { errors?: Maybe<Array<(
      { __typename?: 'FieldError' }
      & Pick<FieldError, 'field' | 'message'>
    )>> }
  )> }
);

export type MoveQuestionMutationVariables = Exact<{
  id: Scalars['String'];
  collectionId: Scalars['String'];
  direction: Direction;
}>;


export type MoveQuestionMutation = (
  { __typename?: 'Mutation' }
  & { moveQuestion?: Maybe<(
    { __typename?: 'QuestionChangeResponse' }
    & Pick<QuestionChangeResponse, 'questionId' | 'collectionId' | 'newOrder' | 'oldOrder'>
    & { errors?: Maybe<Array<(
      { __typename?: 'FieldError' }
      & Pick<FieldError, 'field' | 'message'>
    )>> }
  )> }
);

export type MutateCollectionMutationVariables = Exact<{
  name: Scalars['String'];
  orderEnforced?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<Array<TagInput> | TagInput>;
  id?: Maybe<Scalars['String']>;
}>;


export type MutateCollectionMutation = (
  { __typename?: 'Mutation' }
  & { mutateCollection?: Maybe<(
    { __typename?: 'CollectionResponse' }
    & { errors?: Maybe<Array<(
      { __typename?: 'FieldError' }
      & Pick<FieldError, 'field' | 'message'>
    )>>, collection?: Maybe<(
      { __typename?: 'Collection' }
      & Pick<Collection, 'id' | 'name'>
      & { tags: Array<(
        { __typename?: 'Tag' }
        & Pick<Tag, 'id' | 'name'>
      )> }
    )> }
  )> }
);

export type MutateQuestionMutationVariables = Exact<{
  text: Scalars['String'];
  collectionId?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Float']>;
  tags?: Maybe<Array<TagInput> | TagInput>;
  id?: Maybe<Scalars['String']>;
}>;


export type MutateQuestionMutation = (
  { __typename?: 'Mutation' }
  & { mutateQuestion?: Maybe<(
    { __typename?: 'QuestionResponse' }
    & { errors?: Maybe<Array<(
      { __typename?: 'FieldError' }
      & Pick<FieldError, 'field' | 'message'>
    )>>, question?: Maybe<(
      { __typename?: 'Question' }
      & Pick<Question, 'id' | 'text'>
      & { collections: Array<(
        { __typename?: 'QuestionInCollection' }
        & Pick<QuestionInCollection, 'order'>
        & { collection: (
          { __typename?: 'Collection' }
          & Pick<Collection, 'id' | 'name' | 'orderEnforced'>
          & { tags: Array<(
            { __typename?: 'Tag' }
            & Pick<Tag, 'id' | 'name'>
          )> }
        ) }
      )> }
    )> }
  )> }
);

export type RemoveQuestionsFromCollectionMutationVariables = Exact<{
  id: Scalars['String'];
  questionIds: Array<Scalars['String']> | Scalars['String'];
}>;


export type RemoveQuestionsFromCollectionMutation = (
  { __typename?: 'Mutation' }
  & { removeQuestions?: Maybe<(
    { __typename?: 'CollectionAddRemoveQuestionResponse' }
    & Pick<CollectionAddRemoveQuestionResponse, 'questionIds'>
    & { errors?: Maybe<Array<(
      { __typename?: 'FieldError' }
      & Pick<FieldError, 'field' | 'message'>
    )>> }
  )> }
);

export type GetCollectionQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetCollectionQuery = (
  { __typename?: 'Query' }
  & { collection?: Maybe<(
    { __typename?: 'Collection' }
    & Pick<Collection, 'id' | 'name' | 'questionCount' | 'orderEnforced'>
    & { questions: Array<(
      { __typename?: 'QuestionInCollection' }
      & Pick<QuestionInCollection, 'order'>
      & { question: (
        { __typename?: 'Question' }
        & Pick<Question, 'id' | 'text'>
      ) }
    )> }
  )> }
);

export type GetCollectionWithTagsQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetCollectionWithTagsQuery = (
  { __typename?: 'Query' }
  & { collection?: Maybe<(
    { __typename?: 'Collection' }
    & Pick<Collection, 'id' | 'name' | 'questionCount' | 'orderEnforced'>
    & { tags: Array<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'name'>
    )> }
  )> }
);

export type ListCollectionsQueryVariables = Exact<{
  max?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
}>;


export type ListCollectionsQuery = (
  { __typename?: 'Query' }
  & { collections: Array<(
    { __typename?: 'Collection' }
    & Pick<Collection, 'name' | 'id' | 'questionCount'>
  )> }
);

export type ListQuestionsQueryVariables = Exact<{
  filter?: Maybe<Scalars['String']>;
  collectionId?: Maybe<Scalars['String']>;
  max?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
}>;


export type ListQuestionsQuery = (
  { __typename?: 'Query' }
  & { questions: Array<(
    { __typename?: 'Question' }
    & Pick<Question, 'id' | 'text'>
    & { collections: Array<(
      { __typename?: 'QuestionInCollection' }
      & Pick<QuestionInCollection, 'order'>
      & { collection: (
        { __typename?: 'Collection' }
        & Pick<Collection, 'id' | 'name'>
      ) }
    )>, tags: Array<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'name'>
    )> }
  )> }
);

export type ListTagsQueryVariables = Exact<{
  filter?: Maybe<Scalars['String']>;
  max?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
}>;


export type ListTagsQuery = (
  { __typename?: 'Query' }
  & { tags: Array<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'id' | 'name'>
  )> }
);

export type QotdQueryVariables = Exact<{
  collectionIds?: Maybe<Scalars['String']>;
}>;


export type QotdQuery = (
  { __typename?: 'Query' }
  & { qotd?: Maybe<(
    { __typename?: 'Question' }
    & Pick<Question, 'id' | 'text'>
    & { collections: Array<(
      { __typename?: 'QuestionInCollection' }
      & Pick<QuestionInCollection, 'order'>
      & { collection: (
        { __typename?: 'Collection' }
        & Pick<Collection, 'id' | 'name'>
      ) }
    )>, tags: Array<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'name'>
    )> }
  )> }
);


export const AddQuestionsToCollectionDocument = gql`
    mutation AddQuestionsToCollection($id: String!, $questionIds: [String!]!) {
  addQuestions(id: $id, questionIds: $questionIds) {
    errors {
      field
      message
    }
    questionIds
  }
}
    `;
export type AddQuestionsToCollectionMutationFn = Apollo.MutationFunction<AddQuestionsToCollectionMutation, AddQuestionsToCollectionMutationVariables>;

/**
 * __useAddQuestionsToCollectionMutation__
 *
 * To run a mutation, you first call `useAddQuestionsToCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddQuestionsToCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addQuestionsToCollectionMutation, { data, loading, error }] = useAddQuestionsToCollectionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      questionIds: // value for 'questionIds'
 *   },
 * });
 */
export function useAddQuestionsToCollectionMutation(baseOptions?: Apollo.MutationHookOptions<AddQuestionsToCollectionMutation, AddQuestionsToCollectionMutationVariables>) {
        return Apollo.useMutation<AddQuestionsToCollectionMutation, AddQuestionsToCollectionMutationVariables>(AddQuestionsToCollectionDocument, baseOptions);
      }
export type AddQuestionsToCollectionMutationHookResult = ReturnType<typeof useAddQuestionsToCollectionMutation>;
export type AddQuestionsToCollectionMutationResult = Apollo.MutationResult<AddQuestionsToCollectionMutation>;
export type AddQuestionsToCollectionMutationOptions = Apollo.BaseMutationOptions<AddQuestionsToCollectionMutation, AddQuestionsToCollectionMutationVariables>;
export const CreateQuestionsDocument = gql`
    mutation CreateQuestions($questions: [String!]!, $collectionId: String, $tags: [TagInput!]) {
  createQuestions(questions: $questions, collectionId: $collectionId, tags: $tags) {
    errors {
      field
      message
    }
    questions {
      id
      text
      collections {
        order
        collection {
          id
          name
          orderEnforced
          tags {
            id
            name
          }
        }
      }
    }
  }
}
    `;
export type CreateQuestionsMutationFn = Apollo.MutationFunction<CreateQuestionsMutation, CreateQuestionsMutationVariables>;

/**
 * __useCreateQuestionsMutation__
 *
 * To run a mutation, you first call `useCreateQuestionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQuestionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQuestionsMutation, { data, loading, error }] = useCreateQuestionsMutation({
 *   variables: {
 *      questions: // value for 'questions'
 *      collectionId: // value for 'collectionId'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useCreateQuestionsMutation(baseOptions?: Apollo.MutationHookOptions<CreateQuestionsMutation, CreateQuestionsMutationVariables>) {
        return Apollo.useMutation<CreateQuestionsMutation, CreateQuestionsMutationVariables>(CreateQuestionsDocument, baseOptions);
      }
export type CreateQuestionsMutationHookResult = ReturnType<typeof useCreateQuestionsMutation>;
export type CreateQuestionsMutationResult = Apollo.MutationResult<CreateQuestionsMutation>;
export type CreateQuestionsMutationOptions = Apollo.BaseMutationOptions<CreateQuestionsMutation, CreateQuestionsMutationVariables>;
export const CreateTagDocument = gql`
    mutation CreateTag($name: String!) {
  createTag(name: $name) {
    errors {
      field
      message
    }
    tag {
      id
      name
    }
  }
}
    `;
export type CreateTagMutationFn = Apollo.MutationFunction<CreateTagMutation, CreateTagMutationVariables>;

/**
 * __useCreateTagMutation__
 *
 * To run a mutation, you first call `useCreateTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTagMutation, { data, loading, error }] = useCreateTagMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateTagMutation(baseOptions?: Apollo.MutationHookOptions<CreateTagMutation, CreateTagMutationVariables>) {
        return Apollo.useMutation<CreateTagMutation, CreateTagMutationVariables>(CreateTagDocument, baseOptions);
      }
export type CreateTagMutationHookResult = ReturnType<typeof useCreateTagMutation>;
export type CreateTagMutationResult = Apollo.MutationResult<CreateTagMutation>;
export type CreateTagMutationOptions = Apollo.BaseMutationOptions<CreateTagMutation, CreateTagMutationVariables>;
export const DeleteCollectionDocument = gql`
    mutation DeleteCollection($id: String!, $deleteQuestions: Boolean) {
  deleteCollection(id: $id, deleteQuestions: $deleteQuestions) {
    errors {
      field
      message
    }
    deletedIds
  }
}
    `;
export type DeleteCollectionMutationFn = Apollo.MutationFunction<DeleteCollectionMutation, DeleteCollectionMutationVariables>;

/**
 * __useDeleteCollectionMutation__
 *
 * To run a mutation, you first call `useDeleteCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCollectionMutation, { data, loading, error }] = useDeleteCollectionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      deleteQuestions: // value for 'deleteQuestions'
 *   },
 * });
 */
export function useDeleteCollectionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCollectionMutation, DeleteCollectionMutationVariables>) {
        return Apollo.useMutation<DeleteCollectionMutation, DeleteCollectionMutationVariables>(DeleteCollectionDocument, baseOptions);
      }
export type DeleteCollectionMutationHookResult = ReturnType<typeof useDeleteCollectionMutation>;
export type DeleteCollectionMutationResult = Apollo.MutationResult<DeleteCollectionMutation>;
export type DeleteCollectionMutationOptions = Apollo.BaseMutationOptions<DeleteCollectionMutation, DeleteCollectionMutationVariables>;
export const DeleteQuestionsDocument = gql`
    mutation DeleteQuestions($ids: [String!]!) {
  deleteQuestions(ids: $ids) {
    errors {
      field
      message
    }
    deletedIds
  }
}
    `;
export type DeleteQuestionsMutationFn = Apollo.MutationFunction<DeleteQuestionsMutation, DeleteQuestionsMutationVariables>;

/**
 * __useDeleteQuestionsMutation__
 *
 * To run a mutation, you first call `useDeleteQuestionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteQuestionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteQuestionsMutation, { data, loading, error }] = useDeleteQuestionsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteQuestionsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteQuestionsMutation, DeleteQuestionsMutationVariables>) {
        return Apollo.useMutation<DeleteQuestionsMutation, DeleteQuestionsMutationVariables>(DeleteQuestionsDocument, baseOptions);
      }
export type DeleteQuestionsMutationHookResult = ReturnType<typeof useDeleteQuestionsMutation>;
export type DeleteQuestionsMutationResult = Apollo.MutationResult<DeleteQuestionsMutation>;
export type DeleteQuestionsMutationOptions = Apollo.BaseMutationOptions<DeleteQuestionsMutation, DeleteQuestionsMutationVariables>;
export const MoveQuestionDocument = gql`
    mutation MoveQuestion($id: String!, $collectionId: String!, $direction: Direction!) {
  moveQuestion(id: $id, collectionId: $collectionId, direction: $direction) {
    errors {
      field
      message
    }
    questionId
    collectionId
    newOrder
    oldOrder
  }
}
    `;
export type MoveQuestionMutationFn = Apollo.MutationFunction<MoveQuestionMutation, MoveQuestionMutationVariables>;

/**
 * __useMoveQuestionMutation__
 *
 * To run a mutation, you first call `useMoveQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveQuestionMutation, { data, loading, error }] = useMoveQuestionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      collectionId: // value for 'collectionId'
 *      direction: // value for 'direction'
 *   },
 * });
 */
export function useMoveQuestionMutation(baseOptions?: Apollo.MutationHookOptions<MoveQuestionMutation, MoveQuestionMutationVariables>) {
        return Apollo.useMutation<MoveQuestionMutation, MoveQuestionMutationVariables>(MoveQuestionDocument, baseOptions);
      }
export type MoveQuestionMutationHookResult = ReturnType<typeof useMoveQuestionMutation>;
export type MoveQuestionMutationResult = Apollo.MutationResult<MoveQuestionMutation>;
export type MoveQuestionMutationOptions = Apollo.BaseMutationOptions<MoveQuestionMutation, MoveQuestionMutationVariables>;
export const MutateCollectionDocument = gql`
    mutation MutateCollection($name: String!, $orderEnforced: Boolean, $tags: [TagInput!], $id: String) {
  mutateCollection(
    name: $name
    orderEnforced: $orderEnforced
    tags: $tags
    id: $id
  ) {
    errors {
      field
      message
    }
    collection {
      id
      name
      tags {
        id
        name
      }
    }
  }
}
    `;
export type MutateCollectionMutationFn = Apollo.MutationFunction<MutateCollectionMutation, MutateCollectionMutationVariables>;

/**
 * __useMutateCollectionMutation__
 *
 * To run a mutation, you first call `useMutateCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMutateCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mutateCollectionMutation, { data, loading, error }] = useMutateCollectionMutation({
 *   variables: {
 *      name: // value for 'name'
 *      orderEnforced: // value for 'orderEnforced'
 *      tags: // value for 'tags'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMutateCollectionMutation(baseOptions?: Apollo.MutationHookOptions<MutateCollectionMutation, MutateCollectionMutationVariables>) {
        return Apollo.useMutation<MutateCollectionMutation, MutateCollectionMutationVariables>(MutateCollectionDocument, baseOptions);
      }
export type MutateCollectionMutationHookResult = ReturnType<typeof useMutateCollectionMutation>;
export type MutateCollectionMutationResult = Apollo.MutationResult<MutateCollectionMutation>;
export type MutateCollectionMutationOptions = Apollo.BaseMutationOptions<MutateCollectionMutation, MutateCollectionMutationVariables>;
export const MutateQuestionDocument = gql`
    mutation MutateQuestion($text: String!, $collectionId: String, $order: Float, $tags: [TagInput!], $id: String) {
  mutateQuestion(
    text: $text
    collectionId: $collectionId
    order: $order
    tags: $tags
    id: $id
  ) {
    errors {
      field
      message
    }
    question {
      id
      text
      collections {
        order
        collection {
          id
          name
          orderEnforced
          tags {
            id
            name
          }
        }
      }
    }
  }
}
    `;
export type MutateQuestionMutationFn = Apollo.MutationFunction<MutateQuestionMutation, MutateQuestionMutationVariables>;

/**
 * __useMutateQuestionMutation__
 *
 * To run a mutation, you first call `useMutateQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMutateQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mutateQuestionMutation, { data, loading, error }] = useMutateQuestionMutation({
 *   variables: {
 *      text: // value for 'text'
 *      collectionId: // value for 'collectionId'
 *      order: // value for 'order'
 *      tags: // value for 'tags'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMutateQuestionMutation(baseOptions?: Apollo.MutationHookOptions<MutateQuestionMutation, MutateQuestionMutationVariables>) {
        return Apollo.useMutation<MutateQuestionMutation, MutateQuestionMutationVariables>(MutateQuestionDocument, baseOptions);
      }
export type MutateQuestionMutationHookResult = ReturnType<typeof useMutateQuestionMutation>;
export type MutateQuestionMutationResult = Apollo.MutationResult<MutateQuestionMutation>;
export type MutateQuestionMutationOptions = Apollo.BaseMutationOptions<MutateQuestionMutation, MutateQuestionMutationVariables>;
export const RemoveQuestionsFromCollectionDocument = gql`
    mutation RemoveQuestionsFromCollection($id: String!, $questionIds: [String!]!) {
  removeQuestions(id: $id, questionIds: $questionIds) {
    errors {
      field
      message
    }
    questionIds
  }
}
    `;
export type RemoveQuestionsFromCollectionMutationFn = Apollo.MutationFunction<RemoveQuestionsFromCollectionMutation, RemoveQuestionsFromCollectionMutationVariables>;

/**
 * __useRemoveQuestionsFromCollectionMutation__
 *
 * To run a mutation, you first call `useRemoveQuestionsFromCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveQuestionsFromCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeQuestionsFromCollectionMutation, { data, loading, error }] = useRemoveQuestionsFromCollectionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      questionIds: // value for 'questionIds'
 *   },
 * });
 */
export function useRemoveQuestionsFromCollectionMutation(baseOptions?: Apollo.MutationHookOptions<RemoveQuestionsFromCollectionMutation, RemoveQuestionsFromCollectionMutationVariables>) {
        return Apollo.useMutation<RemoveQuestionsFromCollectionMutation, RemoveQuestionsFromCollectionMutationVariables>(RemoveQuestionsFromCollectionDocument, baseOptions);
      }
export type RemoveQuestionsFromCollectionMutationHookResult = ReturnType<typeof useRemoveQuestionsFromCollectionMutation>;
export type RemoveQuestionsFromCollectionMutationResult = Apollo.MutationResult<RemoveQuestionsFromCollectionMutation>;
export type RemoveQuestionsFromCollectionMutationOptions = Apollo.BaseMutationOptions<RemoveQuestionsFromCollectionMutation, RemoveQuestionsFromCollectionMutationVariables>;
export const GetCollectionDocument = gql`
    query GetCollection($id: String!) {
  collection(id: $id) {
    id
    name
    questionCount
    orderEnforced
    questions {
      order
      question {
        id
        text
      }
    }
  }
}
    `;

/**
 * __useGetCollectionQuery__
 *
 * To run a query within a React component, call `useGetCollectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCollectionQuery(baseOptions: Apollo.QueryHookOptions<GetCollectionQuery, GetCollectionQueryVariables>) {
        return Apollo.useQuery<GetCollectionQuery, GetCollectionQueryVariables>(GetCollectionDocument, baseOptions);
      }
export function useGetCollectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCollectionQuery, GetCollectionQueryVariables>) {
          return Apollo.useLazyQuery<GetCollectionQuery, GetCollectionQueryVariables>(GetCollectionDocument, baseOptions);
        }
export type GetCollectionQueryHookResult = ReturnType<typeof useGetCollectionQuery>;
export type GetCollectionLazyQueryHookResult = ReturnType<typeof useGetCollectionLazyQuery>;
export type GetCollectionQueryResult = Apollo.QueryResult<GetCollectionQuery, GetCollectionQueryVariables>;
export const GetCollectionWithTagsDocument = gql`
    query GetCollectionWithTags($id: String!) {
  collection(id: $id) {
    id
    name
    questionCount
    orderEnforced
    tags {
      id
      name
    }
  }
}
    `;

/**
 * __useGetCollectionWithTagsQuery__
 *
 * To run a query within a React component, call `useGetCollectionWithTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionWithTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectionWithTagsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCollectionWithTagsQuery(baseOptions: Apollo.QueryHookOptions<GetCollectionWithTagsQuery, GetCollectionWithTagsQueryVariables>) {
        return Apollo.useQuery<GetCollectionWithTagsQuery, GetCollectionWithTagsQueryVariables>(GetCollectionWithTagsDocument, baseOptions);
      }
export function useGetCollectionWithTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCollectionWithTagsQuery, GetCollectionWithTagsQueryVariables>) {
          return Apollo.useLazyQuery<GetCollectionWithTagsQuery, GetCollectionWithTagsQueryVariables>(GetCollectionWithTagsDocument, baseOptions);
        }
export type GetCollectionWithTagsQueryHookResult = ReturnType<typeof useGetCollectionWithTagsQuery>;
export type GetCollectionWithTagsLazyQueryHookResult = ReturnType<typeof useGetCollectionWithTagsLazyQuery>;
export type GetCollectionWithTagsQueryResult = Apollo.QueryResult<GetCollectionWithTagsQuery, GetCollectionWithTagsQueryVariables>;
export const ListCollectionsDocument = gql`
    query ListCollections($max: Float, $offset: Float) {
  collections(max: $max, offset: $offset) {
    name
    id
    questionCount
  }
}
    `;

/**
 * __useListCollectionsQuery__
 *
 * To run a query within a React component, call `useListCollectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCollectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCollectionsQuery({
 *   variables: {
 *      max: // value for 'max'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useListCollectionsQuery(baseOptions?: Apollo.QueryHookOptions<ListCollectionsQuery, ListCollectionsQueryVariables>) {
        return Apollo.useQuery<ListCollectionsQuery, ListCollectionsQueryVariables>(ListCollectionsDocument, baseOptions);
      }
export function useListCollectionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListCollectionsQuery, ListCollectionsQueryVariables>) {
          return Apollo.useLazyQuery<ListCollectionsQuery, ListCollectionsQueryVariables>(ListCollectionsDocument, baseOptions);
        }
export type ListCollectionsQueryHookResult = ReturnType<typeof useListCollectionsQuery>;
export type ListCollectionsLazyQueryHookResult = ReturnType<typeof useListCollectionsLazyQuery>;
export type ListCollectionsQueryResult = Apollo.QueryResult<ListCollectionsQuery, ListCollectionsQueryVariables>;
export const ListQuestionsDocument = gql`
    query ListQuestions($filter: String, $collectionId: String, $max: Float, $offset: Float) {
  questions(
    filter: $filter
    collectionId: $collectionId
    max: $max
    offset: $offset
  ) {
    id
    text
    collections {
      order
      collection {
        id
        name
      }
    }
    tags {
      id
      name
    }
  }
}
    `;

/**
 * __useListQuestionsQuery__
 *
 * To run a query within a React component, call `useListQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListQuestionsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      collectionId: // value for 'collectionId'
 *      max: // value for 'max'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useListQuestionsQuery(baseOptions?: Apollo.QueryHookOptions<ListQuestionsQuery, ListQuestionsQueryVariables>) {
        return Apollo.useQuery<ListQuestionsQuery, ListQuestionsQueryVariables>(ListQuestionsDocument, baseOptions);
      }
export function useListQuestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListQuestionsQuery, ListQuestionsQueryVariables>) {
          return Apollo.useLazyQuery<ListQuestionsQuery, ListQuestionsQueryVariables>(ListQuestionsDocument, baseOptions);
        }
export type ListQuestionsQueryHookResult = ReturnType<typeof useListQuestionsQuery>;
export type ListQuestionsLazyQueryHookResult = ReturnType<typeof useListQuestionsLazyQuery>;
export type ListQuestionsQueryResult = Apollo.QueryResult<ListQuestionsQuery, ListQuestionsQueryVariables>;
export const ListTagsDocument = gql`
    query ListTags($filter: String, $max: Float, $offset: Float) {
  tags(filter: $filter, max: $max, offset: $offset) {
    id
    name
  }
}
    `;

/**
 * __useListTagsQuery__
 *
 * To run a query within a React component, call `useListTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTagsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      max: // value for 'max'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useListTagsQuery(baseOptions?: Apollo.QueryHookOptions<ListTagsQuery, ListTagsQueryVariables>) {
        return Apollo.useQuery<ListTagsQuery, ListTagsQueryVariables>(ListTagsDocument, baseOptions);
      }
export function useListTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListTagsQuery, ListTagsQueryVariables>) {
          return Apollo.useLazyQuery<ListTagsQuery, ListTagsQueryVariables>(ListTagsDocument, baseOptions);
        }
export type ListTagsQueryHookResult = ReturnType<typeof useListTagsQuery>;
export type ListTagsLazyQueryHookResult = ReturnType<typeof useListTagsLazyQuery>;
export type ListTagsQueryResult = Apollo.QueryResult<ListTagsQuery, ListTagsQueryVariables>;
export const QotdDocument = gql`
    query Qotd($collectionIds: String) {
  qotd(collectionIds: $collectionIds) {
    id
    text
    collections {
      order
      collection {
        id
        name
      }
    }
    tags {
      id
      name
    }
  }
}
    `;

/**
 * __useQotdQuery__
 *
 * To run a query within a React component, call `useQotdQuery` and pass it any options that fit your needs.
 * When your component renders, `useQotdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQotdQuery({
 *   variables: {
 *      collectionIds: // value for 'collectionIds'
 *   },
 * });
 */
export function useQotdQuery(baseOptions?: Apollo.QueryHookOptions<QotdQuery, QotdQueryVariables>) {
        return Apollo.useQuery<QotdQuery, QotdQueryVariables>(QotdDocument, baseOptions);
      }
export function useQotdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QotdQuery, QotdQueryVariables>) {
          return Apollo.useLazyQuery<QotdQuery, QotdQueryVariables>(QotdDocument, baseOptions);
        }
export type QotdQueryHookResult = ReturnType<typeof useQotdQuery>;
export type QotdLazyQueryHookResult = ReturnType<typeof useQotdLazyQuery>;
export type QotdQueryResult = Apollo.QueryResult<QotdQuery, QotdQueryVariables>;