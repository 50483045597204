import React from 'react';
import { Badge, Box, Checkbox, Icon, IconButton, Tooltip } from '@chakra-ui/react';
import { ButtonWithPopover } from './ButtonWithPopover';
import { DeleteButtonWithPopover } from './DeleteButtonWithPopover';
import { Direction } from 'src/generated/graphql';
import { ListTags } from './ListTags';
import { QuestionReturnListQuestions } from 'src/types/Question';
import { RowItem } from './RowItem';

import { ReactComponent as ArrowButtonDown2 } from '@streamlinehq/streamlinehq/img/streamline-regular/arrows-diagrams/arrows/arrow-button-down-2.svg';
import { ReactComponent as ArrowButtonUp2 } from '@streamlinehq/streamlinehq/img/streamline-regular/arrows-diagrams/arrows/arrow-button-up-2.svg';
import { ReactComponent as CommonFileTextEdit } from '@streamlinehq/streamlinehq/img/streamline-regular/files-folders/common-files/common-file-text-edit.svg';
import { ReactComponent as TaskListAdd1 } from '@streamlinehq/streamlinehq/img/streamline-regular/work-office-companies/tasks/task-list-add-1.svg';
import { ReactComponent as TaskListDelete } from '@streamlinehq/streamlinehq/img/streamline-regular/work-office-companies/tasks/task-list-delete.svg';

export const QuestionItem: React.FC<{
    question: QuestionReturnListQuestions;
    collectionId?: string;
    checked?: boolean;
    onQuestionCheck?: (questionId: string, checked: boolean) => void;
    onEditClick?: (questionId: string) => void;
    onDeleteClick?: (questionId: string) => Promise<void>;
    onAddToCollectionClick?: (questionIds: string[]) => Promise<void>;
    onRemoveFromCollectionClick?: (questionIds: string[], collectionId: string) => Promise<void>;
    onMoveQuestionClick: (questionId: string, direction: Direction) => Promise<void>;
    order?: number;
    isLast?: boolean;
    orderEnforced?: boolean;
}> = (props) => {
    return (
        <RowItem>
            <Box gridColumn="1" gridRow="1 / span 2" display="flex" alignItems="center">
                <Checkbox
                    value={props.question.id}
                    isChecked={props.checked ?? false}
                    onChange={(e) => {
                        props.onQuestionCheck?.(props.question.id, !!e.target.checked);
                    }}
                />
            </Box>
            <Box gridColumn="2" gridRow={'1'} display="flex" alignItems="center" gridGap={2}>
                {props.order !== undefined && props.orderEnforced && (
                    <Badge fontSize="10px" size="sm">
                        {props.order}
                    </Badge>
                )}
                {props.question.text}
                {props.collectionId === undefined && (
                    <Tooltip
                        hasArrow
                        bg={props.question.collections.length === 0 ? 'gray.300' : 'black'}
                        label={
                            props.question.collections.length === 0
                                ? 'No Collections'
                                : props.question.collections.map((collection) => collection.collection.name).join('\n')
                        }>
                        <Badge fontSize="10px" size="sm">
                            {props.question.collections.length}
                        </Badge>
                    </Tooltip>
                )}
            </Box>
            <Box gridColumn="2" gridRow="2">
                <ListTags tags={props.question.tags} />
            </Box>
            <Box justifySelf="flex-end" gridRow="1 / span 2" gridColumn="3" gridGap={1} display="flex">
                {props.collectionId ? (
                    <>
                        {props.orderEnforced && (
                            <>
                                <IconButton
                                    size="sm"
                                    icon={
                                        <Icon>
                                            <ArrowButtonUp2 />
                                        </Icon>
                                    }
                                    title="Move Question Up"
                                    aria-label="move question up"
                                    disabled={props.order === 0}
                                    onClick={() => props.onMoveQuestionClick(props.question.id, Direction.Up)}
                                />
                                <IconButton
                                    size="sm"
                                    icon={
                                        <Icon>
                                            <ArrowButtonDown2 />
                                        </Icon>
                                    }
                                    title="Move Question Down"
                                    aria-label="move question down"
                                    disabled={props.isLast}
                                    onClick={() => props.onMoveQuestionClick(props.question.id, Direction.Down)}
                                />
                            </>
                        )}
                        <ButtonWithPopover
                            popoverTitle="Remove Question from Collection?"
                            confirmText="Remove Question"
                            popoverContent="This does not delete the Question"
                            onConfirm={async () => {
                                if (props.collectionId) {
                                    return props.onRemoveFromCollectionClick?.([props.question.id], props.collectionId);
                                }
                            }}>
                            <IconButton
                                size="sm"
                                icon={
                                    <Icon>
                                        <TaskListDelete />
                                    </Icon>
                                }
                                title="Remove Question from Collection"
                                aria-label="remove question from collection"
                            />
                        </ButtonWithPopover>
                    </>
                ) : (
                    <>
                        <IconButton
                            size="sm"
                            icon={
                                <Icon>
                                    <TaskListAdd1 />
                                </Icon>
                            }
                            title="Add Question to Collection"
                            aria-label="add question to collection"
                            onClick={async () => props.onAddToCollectionClick?.([props.question.id])}
                        />
                    </>
                )}
                <IconButton
                    size="sm"
                    icon={
                        <Icon>
                            <CommonFileTextEdit />
                        </Icon>
                    }
                    aria-label="edit question"
                    title="Edit Question"
                    onClick={() => {
                        props.onEditClick?.(props.question.id);
                    }}
                />
                <DeleteButtonWithPopover
                    title="Delete Question"
                    popoverTitle="Delete Question?"
                    popoverContent="This will delete the question from all collections it's associated to."
                    confirmText="Delete"
                    onConfirm={async () => {
                        await props.onDeleteClick?.(props.question.id);
                    }}
                />
            </Box>
        </RowItem>
    );
};
