import React, { useEffect, useState } from 'react';
import shuffle from 'lodash.shuffle';
import { Box, Button } from '@chakra-ui/react';
import { Frame } from 'src/components/layout/Frame';
import { QuestionInCollection } from 'src/components/QuestionInCollection';
import { QuestionReturnGetCollection } from 'src/types/Question';
import { useGetCollectionQuery } from '../../generated/graphql';
import { useHistory, useParams } from 'react-router-dom';

export const Question = () => {
    const { collectionId, questionId } = useParams<{ collectionId?: string; questionId?: string }>();
    const collectionResult = useGetCollectionQuery({ variables: { id: collectionId ?? '' } });
    const [questions, setQuestions] = useState([] as QuestionReturnGetCollection[]);
    const collection = collectionResult.data?.collection;
    const history = useHistory();

    useEffect(() => {
        setQuestions(collection?.orderEnforced ? collection?.questions || [] : shuffle(collection?.questions || []));
    }, [collection?.orderEnforced, collection?.questions, collection?.id]);

    let questionIndex = questionId ? questions?.findIndex((q) => q.question.id === questionId) : 0;
    questionIndex = !questionIndex || questionIndex === -1 ? 0 : questionIndex;
    const question = questions?.[questionIndex];
    const prev = questionIndex > 0 ? questions?.[questionIndex - 1] : null;
    const next = questionIndex < (questions?.length ?? 0) - 1 ? questions?.[questionIndex + 1] : null;

    const backToHome = () => history.push('/');
    const goToFirstCollection = () =>
        history.push(`/collections/${collectionId}/questions/${questions[0]?.question.id}`);

    return (
        <Frame>
            <Box display="grid" gridGap={8} gridTemplateRows={'auto auto'}>
                <QuestionInCollection
                    isLoading={collectionResult.loading || !question}
                    question={question}
                    collection={collection}
                    previous={prev?.question.id}
                    next={next?.question.id}
                    questionIndex={questionIndex}
                />
            </Box>
            <Box display="grid" gridGap={2} gridTemplateColumns={['1fr 1fr']}>
                <Box>
                    <Button onClick={backToHome} aria-label="Home">
                        Back to Home
                    </Button>
                </Box>
                <Box textAlign="right">
                    <Button onClick={goToFirstCollection} aria-label="Back to first question">
                        First question
                    </Button>
                </Box>
            </Box>
        </Frame>
    );
};
