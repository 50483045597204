import React, { useEffect, useState } from 'react';
import { Box, Button } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { NetworkStatus } from '@apollo/client';
import { QuestionCard } from './layout/QuestionCard';
import { useQotdQuery } from 'src/generated/graphql';

export const QOTD = () => {
    const qotdResults = useQotdQuery({ notifyOnNetworkStatusChange: true });
    const qotd = qotdResults.data?.qotd;

    const [qotdState, setQotdState] = useState(qotdResults.data?.qotd);
    const currentCollection = qotdState?.collections[Math.floor(Math.random() * qotdState.collections.length)];

    useEffect(() => {
        setQotdState(qotdResults.data?.qotd);
    }, [qotdResults.data?.qotd]);

    const reload = () => {
        return qotdResults.refetch();
    };

    const isLoading = qotdResults.loading || qotdResults.networkStatus === NetworkStatus.refetch;

    const footerRenderer = () => {
        return (
            <Box display="grid" gridGap={4} gridTemplateColumns="repeat(2, 1fr)" fontSize="sm">
                <Box justifySelf="flex-start">
                    <Link to={`/collections/${currentCollection?.collection.id}`}>
                        {currentCollection?.collection.name}
                    </Link>
                </Box>
                <Box justifySelf="flex-end">
                    <Button onClick={reload}>Reload</Button>
                </Box>
            </Box>
        );
    };

    return (
        <QuestionCard footerRenderer={footerRenderer}>
            {isLoading ? (
                'Loading...'
            ) : (
                <Box>
                    <Link to={`/collections/${currentCollection?.collection.id}`}>{qotd?.text}</Link>
                </Box>
            )}
        </QuestionCard>
    );
};
