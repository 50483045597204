import React, { ReactElement } from 'react';
import {
    Box,
    Button,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverHeader,
    PopoverTrigger,
} from '@chakra-ui/react';

export interface ButtonWithPopoverProps {
    popoverTitle: string;
    confirmText: string;
    onClose?: () => void;
    onConfirm: () => Promise<void>;
    popoverContent: string | ReactElement | (() => string | ReactElement);
}

export const ButtonWithPopover: React.FC<ButtonWithPopoverProps> = ({
    children,
    popoverTitle,
    onClose,
    onConfirm,
    popoverContent,
    confirmText,
}) => {
    const [isExecuting, setIsExecuting] = React.useState<boolean>(false);

    return (
        <Popover placement="left" onClose={onClose}>
            {(popover) => (
                <>
                    <PopoverTrigger>{children}</PopoverTrigger>
                    <PopoverContent>
                        <PopoverArrow />
                        <PopoverCloseButton />
                        <PopoverHeader>{popoverTitle}</PopoverHeader>
                        <PopoverBody>
                            <Box>{typeof popoverContent === 'function' ? popoverContent() : popoverContent}</Box>
                            <Box textAlign="right">
                                <Button
                                    colorScheme="red"
                                    title={confirmText}
                                    aria-label={confirmText}
                                    isLoading={isExecuting}
                                    onClick={async () => {
                                        try {
                                            setIsExecuting(true);
                                            await onConfirm();
                                        } catch (error) {
                                            console.log(error.message);
                                        } finally {
                                            setIsExecuting(false);
                                            popover.onClose();
                                        }
                                    }}>
                                    {confirmText}
                                </Button>
                            </Box>
                        </PopoverBody>
                    </PopoverContent>
                </>
            )}
        </Popover>
    );
};
