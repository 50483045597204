import React from 'react';
import { Box } from '@chakra-ui/react';

export const RowItem: React.FC<{ noBorder?: boolean }> = ({ children, noBorder }) => {
    return (
        <Box
            display="grid"
            gridGap={2}
            gridTemplateColumns="auto 1fr auto"
            gridTemplateRows="auto auto"
            fontSize={['xs', 'xs', 'md']}
            borderWidth={noBorder ? 0 : '1px'}
            borderRadius={noBorder ? 0 : 4}
            padding={2}
            alignItems="center"
            color="base.700">
            {children}
        </Box>
    );
};
