import debounce from 'lodash.debounce';
import React, { useCallback, useRef, useState } from 'react';
import ReactSelect from 'react-select';
import { Box, Button, Icon, Input, InputGroup, InputLeftElement, Skeleton } from '@chakra-ui/react';
import { Collection } from './Collection';
import { CollectionDetails } from './CollectionDetails';
import { Frame } from 'src/components/layout/Frame';
import { QuestionDetails } from './QuestionDetails';
import { QuestionList } from './QuestionList';
import { SpinnerOverlay } from './SpinnerOverlay';
import {
    useAddQuestionsToCollectionMutation,
    useCreateQuestionsMutation,
    useDeleteCollectionMutation,
    useDeleteQuestionsMutation,
    useGetCollectionWithTagsLazyQuery,
    useListCollectionsQuery,
    useListQuestionsLazyQuery,
    useMutateCollectionMutation,
    useMutateQuestionMutation,
    useRemoveQuestionsFromCollectionMutation,
} from 'src/generated/graphql';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { ReactComponent as Add } from '@streamlinehq/streamlinehq/img/streamline-regular/interface-essential/remove-add/add.svg';
import { ReactComponent as CommonFileTextAdd } from '@streamlinehq/streamlinehq/img/streamline-regular/files-folders/common-files/common-file-text-add.svg';
import { ReactComponent as ListAdd } from '@streamlinehq/streamlinehq/img/streamline-regular/interface-essential/lists/list-add.svg';
import { ReactComponent as Search } from '@streamlinehq/streamlinehq/img/streamline-regular/interface-essential/search/search.svg';

const NO_COLLECTION = '';

export const Admin = () => {
    const { collectionId, questionId } = useParams<{ collectionId?: string; questionId?: string }>();

    const [filterText, setFilterText] = useState<string>('');
    const [isCollectionDetailsOpen, setCollectionDetailsOpen] = useState<boolean>(false);
    const [isQuestionDetailsOpen, setQuestionDetailsOpen] = useState<boolean>(false || !!questionId);
    const [mutateQuestion] = useMutateQuestionMutation();
    const [mutateCollection] = useMutateCollectionMutation();
    const [deleteQuestions] = useDeleteQuestionsMutation();
    const [deleteCollection] = useDeleteCollectionMutation();
    const [addQuestion] = useAddQuestionsToCollectionMutation();
    const [removeQuestion] = useRemoveQuestionsFromCollectionMutation();
    const [createQuestions] = useCreateQuestionsMutation();

    const [getQuestions, questions] = useListQuestionsLazyQuery();
    const [getCollection, collection] = useGetCollectionWithTagsLazyQuery();
    const collectionsResults = useListCollectionsQuery();
    const history = useHistory();

    const fetchQuestionFunction = useRef(getQuestions);
    if (questions.refetch) {
        fetchQuestionFunction.current = (...args: Parameters<typeof getQuestions>) =>
            questions.refetch(args[0]?.variables);
    }

    const fetchCollectionFunction = useRef(getCollection);
    if (collection.refetch) {
        fetchCollectionFunction.current = (...args: Parameters<typeof getCollection>) =>
            collection.refetch(args[0]?.variables);
    }

    const refetchQuestions = useRef(
        debounce((filter: string, id?: string) => {
            fetchQuestionFunction.current({ variables: { filter, collectionId: id } });
        }, 200),
    );

    const refetchCollection = useRef(
        debounce((id: string) => {
            fetchCollectionFunction.current({ variables: { id: id } });
        }, 200),
    );

    useEffect(() => {
        refetchQuestions.current(filterText, collectionId);
    }, [filterText, collectionId]);

    useEffect(() => {
        if (collectionId) {
            refetchCollection.current(collectionId);
        }
    }, [collectionId]);

    const options = [
        { label: 'Select a collection', value: NO_COLLECTION },
        ...(collectionsResults.data?.collections ?? []).map((item) => ({
            label: item.name,
            value: item.id,
        })),
    ];

    return (
        <Frame>
            <Box display="grid" gridGap={4} gridTemplateRows={'auto auto'}>
                <Box>
                    <ReactSelect
                        value={options.find((option) => option.value === (collectionId ?? NO_COLLECTION))}
                        onChange={(option) => {
                            option?.value
                                ? history.push(`/admin/collections/${option.value}`)
                                : history.push('/admin/collections/');
                        }}
                        options={options}
                    />
                </Box>

                {collectionId ? (
                    <Box>
                        <Skeleton isLoaded={!collection.loading}>
                            <Collection
                                collection={collection?.data?.collection}
                                onEditClick={(id: string) => {
                                    setCollectionDetailsOpen(true);
                                }}
                                onDeleteClick={async (id, shouldDeleteQuestions) => {
                                    await deleteCollection({
                                        variables: { id, deleteQuestions: shouldDeleteQuestions },
                                    });
                                    history.push('/admin/collections');
                                    collectionsResults.refetch();
                                    questions.refetch?.({ filter: filterText });
                                }}
                            />
                        </Skeleton>
                    </Box>
                ) : (
                    <Button
                        onClick={() => setCollectionDetailsOpen(true)}
                        leftIcon={
                            <Icon size="sm">
                                <CommonFileTextAdd />
                            </Icon>
                        }>
                        Create new Collection
                    </Button>
                )}

                <Button
                    leftIcon={<Icon size="sm">{collectionId ? <ListAdd /> : <Add />}</Icon>}
                    onClick={() => setQuestionDetailsOpen(true)}>
                    {collectionId ? 'Add New Question to Collection' : 'Add New Question'}
                </Button>

                <Box>
                    <InputGroup size="sm">
                        <InputLeftElement pointerEvents="none">
                            <Icon size="sm">
                                <Search />
                            </Icon>
                        </InputLeftElement>
                        <Input
                            placeholder="Question"
                            value={filterText}
                            onChange={(e) => setFilterText(e.target.value)}
                            size="sm"
                        />
                    </InputGroup>
                </Box>
                <Box>
                    <SpinnerOverlay isLoaded={!questions.loading}>
                        <QuestionList
                            collectionId={collectionId}
                            questions={questions.data?.questions ?? []}
                            onEditClick={(id) => {
                                const questionItem = questions.data?.questions.find((question) => question.id === id);
                                if (questionItem) {
                                    setQuestionDetailsOpen(true);
                                    const url = collectionId
                                        ? `/admin/collections/${collectionId}/questions/${questionItem.id}`
                                        : `/admin/questions/${questionItem.id}`;
                                    history.push(url);
                                }
                            }}
                            onDeleteClick={async (ids) => {
                                const questionItems =
                                    questions.data?.questions.filter((question) => ids.includes(question.id)) ?? [];
                                if (questionItems?.length > 0) {
                                    await deleteQuestions({
                                        variables: { ids: questionItems?.map((question) => question.id) },
                                    });
                                    questions.refetch?.({ filter: filterText, collectionId });
                                }
                            }}
                            onRemoveFromCollectionClick={async (questionIds, collectionToRemove) => {
                                if (questionIds.length > 0) {
                                    await removeQuestion({ variables: { questionIds, id: collectionToRemove } });
                                    questions.refetch?.({ filter: filterText, collectionId });
                                    collection.refetch?.({ id: collectionId });
                                }
                            }}
                            onAddToCollectionClick={async (questionIds, collectionToAdd) => {
                                if (questionIds.length > 0) {
                                    await addQuestion({ variables: { questionIds, id: collectionToAdd } });
                                    questions.refetch?.({ filter: filterText });
                                }
                            }}
                            orderEnforced={collection?.data?.collection?.orderEnforced}
                            onMoveQuestion={async () => {
                                questions.refetch?.({ filter: filterText, collectionId });
                            }}
                        />
                    </SpinnerOverlay>
                </Box>
            </Box>

            <QuestionDetails
                isOpen={isQuestionDetailsOpen}
                onClose={() => {
                    setQuestionDetailsOpen(false);
                    const url = collectionId ? `/admin/collections/${collectionId}` : '/admin/';
                    history.push(url);
                }}
                selectedQuestion={
                    questionId ? questions.data?.questions.find((question) => question.id === questionId) : undefined
                }
                onSave={async (values) => {
                    const order = collection.data?.collection?.questionCount ?? 0;
                    if (values.id && !Array.isArray(values.questions)) {
                        await mutateQuestion({
                            variables: {
                                text: values.questions,
                                collectionId: collectionId,
                                order,
                                tags: values.tags,
                                id: values.id,
                            },
                        });
                    } else if (!values.id && Array.isArray(values.questions)) {
                        await createQuestions({
                            variables: {
                                questions: values.questions,
                                collectionId,
                                tags: values.tags,
                            },
                        });
                    }

                    questions.refetch?.({ filter: filterText, collectionId });
                    if (collectionId) {
                        collection.refetch?.({ id: collectionId });
                    }
                }}
            />

            <CollectionDetails
                isOpen={isCollectionDetailsOpen}
                onClose={() => setCollectionDetailsOpen(false)}
                onSave={async (values) => {
                    await mutateCollection({
                        variables: {
                            name: values.name,
                            orderEnforced: values.orderEnforced,
                            tags: values.tags,
                            id: values.id,
                        },
                    });
                    collectionsResults.refetch();
                    if (collectionId) {
                        collection.refetch?.({ id: collectionId });
                    }
                }}
                collection={collectionId && collection.data?.collection ? collection.data?.collection : undefined}
            />
        </Frame>
    );
};
