import { createBreakpoints } from '@chakra-ui/theme-tools';
import { extendTheme } from '@chakra-ui/react';

const colors = {
    white: '#ffffff',
    black: '#000000',
    gray: {
        lightest: '#f5f4f6',
        light: '#e0dde3',
        medium: '#ada5b6',
        mediumdark: '#7a6e87',
        dark: '#47404f',
        darkest: '#141216',
    },
    purple: {
        light: '#d6d2db',
        medium: '#ada4b6',
        dark: '#70647d',
    },
    orange: {
        light: '#f4ece2',
        medium: '#d3b78f',
        dark: '#ba8e4f',
    },
    yellow: {
        light: '#f2ebcf',
        medium: '#e7daa6',
        dark: '#d0b64e',
    },
    red: {
        light: '#dcc0bc',
        medium: '#a36055',
        dark: '#78473f',
    },
    blue: {
        light: '#d8dde9',
        medium: '#98a7c8',
        dark: '#566d9f',
    },
    green: {
        light: '#cbd1c7',
        medium: '#a1ac9a',
        dark: '#79896f',
    },
};

export const theme = extendTheme({
    colors: {
        base: {
            50: colors.white,
            100: colors.white,
            200: colors.gray.lightest,
            300: colors.gray.light,
            400: colors.gray.medium,
            500: colors.gray.medium,
            600: colors.gray.mediumdark,
            700: colors.gray.dark,
            800: colors.gray.darkest,
            900: colors.black,
        },
        alert: { background: colors.red.light, text: colors.red.dark },
        card: {
            background: colors.white,
            text: colors.purple.dark,
            border: colors.purple.medium,
        },
    },
    breakpoints: createBreakpoints({
        sm: '320px',
        md: '768px',
        lg: '1024px',
        xl: '1200px',
    }),
});
