import React from 'react';
import { Box } from '@chakra-ui/react';
import { CollectionReturnGetCollection } from 'src/types/Collection';
import { QuestionCard } from './layout/QuestionCard';
import { QuestionReturnGetCollection } from 'src/types/Question';
import { useHistory } from 'react-router-dom';

const PrevNextLink = (props: { children: React.ReactNode; 'aria-label'?: string; onClick?: () => void }) => {
    return (
        <Box fontSize={['2xl', '3xl', '4xl']} fontWeight="bold" color="base.300">
            <Box cursor="pointer" onClick={props.onClick} aria-label={props['aria-label']} role="button">
                {props.children}
            </Box>
        </Box>
    );
};

export const QuestionInCollection = (props: {
    question?: QuestionReturnGetCollection;
    collection?: CollectionReturnGetCollection;
    isLoading?: boolean;
    previous?: string;
    next?: string;
    questionIndex: number;
}) => {
    const { question, collection, isLoading, previous, next, questionIndex } = props;
    const history = useHistory();

    const footerRenderer = () => {
        return (
            <Box color="base.400" fontSize="sm">
                {isLoading ? '' : `${questionIndex + 1} of ${collection?.questionCount}`}
            </Box>
        );
    };

    const headerRenderer = () => {
        return (
            <Box color="base.400" fontSize="sm" textAlign="center">
                {isLoading ? '' : collection?.name}{' '}
            </Box>
        );
    };

    const prevUrl = `/collections/${collection?.id}/questions/${previous}`;
    const nextUrl = `/collections/${collection?.id}/questions/${next}`;

    const getNext = next
        ? () => {
              if (next) {
                  history.push(nextUrl);
              }
          }
        : undefined;

    const getPrevious = previous
        ? () => {
              if (previous) {
                  history.push(prevUrl);
              }
          }
        : undefined;

    const previousRenderer = previous
        ? () => {
              return (
                  <PrevNextLink onClick={getPrevious} aria-label="Previous">
                      &lt;
                  </PrevNextLink>
              );
          }
        : undefined;

    const nextRenderer = next
        ? () => {
              return (
                  <PrevNextLink aria-label="Next" onClick={getNext}>
                      &gt;
                  </PrevNextLink>
              );
          }
        : undefined;

    return (
        <QuestionCard
            swipeable={true}
            onSwipeLeft={getNext}
            onSwipeRight={getPrevious}
            footerRenderer={footerRenderer}
            headerRenderer={headerRenderer}
            previousRenderer={previousRenderer}
            nextRenderer={nextRenderer}>
            <Box>{isLoading ? 'Loading...' : question?.question.text}</Box>
        </QuestionCard>
    );
};
