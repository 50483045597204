import React from 'react';
import { Box } from '@chakra-ui/react';
import { Collections } from 'src/components/Collections';
import { Frame } from 'src/components/layout/Frame';
import { QOTD } from 'src/components/QOTD';

export const Home = () => {
    return (
        <Frame>
            <Box display="grid" gridGap={8} gridTemplateRows={'auto auto'}>
                <QOTD />
                <Collections />
            </Box>
        </Frame>
    );
};
