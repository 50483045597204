import React from 'react';
import { Admin } from './pages/Admin/';
import { Home } from './pages/Home';
import { Question } from './pages/Question';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

function Routes() {
    return (
        <Router>
            <Switch>
                <Route path={['/collections/:collectionId/questions/:questionId', '/collections/:collectionId']}>
                    <Question />
                </Route>
                <Route
                    exact
                    path={[
                        '/admin',
                        '/admin/collections/',
                        '/admin/questions/',
                        '/admin/questions/:questionId',
                        '/admin/collections/:collectionId',
                        '/admin/collections/:collectionId/questions/:questionId',
                    ]}>
                    <Admin />
                </Route>
                <Route exact path="/">
                    <Home />
                </Route>
            </Switch>
        </Router>
    );
}

export default Routes;
