import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import Routes from './Routes';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { ChakraProvider } from '@chakra-ui/react';
import { theme } from './theme/theme';

const PORT = window.location.hostname.indexOf('localhost') >= 0 ? ':4000' : '';
const PROTOCOL = window.location.hostname.indexOf('localhost') >= 0 ? 'http' : 'https';

const client = new ApolloClient({
    uri: `${PROTOCOL}://${window.location.hostname}${PORT}/graphql`,
    cache: new InMemoryCache({
        typePolicies: {
            Question: {
                merge: false,
            },
            Collection: {
                merge: false,
            },
            Tag: {
                merge: false,
            },
        },
    }),
});

ReactDOM.render(
    <React.StrictMode>
        <ApolloProvider client={client}>
            <ChakraProvider theme={theme}>
                <Routes />
            </ChakraProvider>
        </ApolloProvider>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
