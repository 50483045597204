import React from 'react';
import { Box } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useListCollectionsQuery } from 'src/generated/graphql';

export const Collections = (props: {}) => {
    const collectionsResults = useListCollectionsQuery({ variables: { max: 6 } });

    const collections = collectionsResults.data?.collections;
    return (
        <Box display="grid" gridGap={[4, null, 8]} gridTemplateColumns="repeat(2, 1fr)" gridAutoRows="6em">
            {collectionsResults.loading
                ? 'Loading Collections...'
                : collections?.map((collection) => {
                      return (
                          <Box
                              key={collection.id}
                              textAlign="center"
                              backgroundColor="base.200"
                              borderRadius="lg"
                              alignItems="center"
                              display="grid">
                              <Link to={`/collections/${collection.id}`}>{collection.name}</Link>
                          </Box>
                      );
                  })}
        </Box>
    );
};
