import React from 'react';
import ReactSelect from 'react-select/creatable';
import { Box, Button, HStack, Icon, IconButton, Tag } from '@chakra-ui/react';
import { debounce } from 'lodash';
import { useCreateTagMutation, useListTagsLazyQuery } from 'src/generated/graphql';

import { ReactComponent as Bin } from '@streamlinehq/streamlinehq/img/streamline-regular/interface-essential/delete/bin.svg';
import { ReactComponent as Bin2 } from '@streamlinehq/streamlinehq/img/streamline-regular/interface-essential/delete/bin-2.svg';
import { ReactComponent as TagsAdd } from '@streamlinehq/streamlinehq/img/streamline-regular/interface-essential/tags-bookmarks/tags-add.svg';

export const ListTags: React.FC<{
    tags: { name: string; id: string }[];
    onTagRemove?: (tagId: string) => void;
    onTagAdd?: (tag: { name: string; id: string }) => void;
}> = ({ tags, onTagAdd, onTagRemove }) => {
    const [hoverItem, setHoverItem] = React.useState<string | null>(null);
    const [isAddingTag, setAddingTag] = React.useState<boolean>(false);
    const [getTags, allTags] = useListTagsLazyQuery();
    const [createTag] = useCreateTagMutation();

    const fetchTags = React.useRef(
        debounce(async (filter: string) => {
            getTags({ variables: { filter } });
        }, 200),
    );

    return (
        <Box display="flex" gridGap={2}>
            {tags.map((tag) => {
                return (
                    <Tag fontSize="10px" size="sm" key={tag.id} paddingLeft={onTagRemove ? 0 : 2} gridGap={1}>
                        {!!onTagRemove && (
                            <IconButton
                                aria-label={`remove tag "${tag.name}"`}
                                title={`Remove Tag "${tag.name}"`}
                                size="xs"
                                onClick={() => {
                                    onTagRemove?.(tag.id);
                                }}
                                onMouseEnter={() => setHoverItem(tag.id)}
                                onMouseLeave={() => setHoverItem(null)}>
                                <Icon>{hoverItem === tag.id ? <Bin2 /> : <Bin />}</Icon>
                            </IconButton>
                        )}
                        {tag.name}
                    </Tag>
                );
            })}
            {isAddingTag && (
                <HStack spacing={1}>
                    <ReactSelect
                        cacheOptions
                        isMulti={false}
                        components={{ DropdownIndicator: null }}
                        styles={{
                            container: (base) => ({ ...base, fontSize: '10px', minWidth: '80px' }),
                            control: (base) => ({ ...base, minHeight: '12px' }),
                            valueContainer: (base) => ({
                                ...base,
                                padding: '0 2px',
                                alignItems: 'center',
                                height: '22px',
                            }),
                            input: (base) => ({ ...base, paddingBottom: '0', paddingTop: '0' }),
                            option: (base) => ({ ...base, padding: '4px' }),
                        }}
                        onFocus={(e) => {
                            fetchTags.current(e.target.nodeValue ?? '');
                        }}
                        onInputChange={(input) => {
                            fetchTags.current(input);
                        }}
                        options={
                            allTags.data?.tags
                                .filter((tag) => !tags.find((existing) => existing.id === tag.id))
                                .map((tag) => ({ label: tag.name, value: tag.id })) ?? []
                        }
                        onCreateOption={async (value) => {
                            const tagResult = await createTag({ variables: { name: value } });
                            if (!tagResult.errors && !tagResult.data?.createTag.errors) {
                                const createdTag = tagResult.data?.createTag.tag;
                                if (createdTag) {
                                    onTagAdd?.({ name: createdTag.name, id: createdTag.id });
                                }
                            }
                            setAddingTag(false);
                        }}
                        onChange={(value) => {
                            if (value) {
                                onTagAdd?.({ name: value.label, id: value.value });
                                setAddingTag(false);
                            }
                        }}
                    />
                    <Button
                        onClick={() => {
                            setAddingTag(false);
                        }}
                        size="xs">
                        x
                    </Button>
                </HStack>
            )}
            {!!onTagAdd && (
                <IconButton
                    aria-label="add tag"
                    title="Add Tag"
                    size="xs"
                    disabled={isAddingTag}
                    onClick={() => {
                        setAddingTag(true);
                    }}>
                    <Icon>
                        <TagsAdd />
                    </Icon>
                </IconButton>
            )}
        </Box>
    );
};
