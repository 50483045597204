import React from 'react';
import { Badge, Box, Checkbox, Icon, IconButton } from '@chakra-ui/react';
import { CollectionReturnGetCollectionWithTags } from 'src/types/Collection';
import { ListTags } from './ListTags';

import { ReactComponent as CommonFileTextEdit } from '@streamlinehq/streamlinehq/img/streamline-regular/files-folders/common-files/common-file-text-edit.svg';
import { DeleteButtonWithPopover } from './DeleteButtonWithPopover';

export const Collection: React.FC<{
    collection: CollectionReturnGetCollectionWithTags | null;
    onDeleteClick: (collectionId: string, deleteQuestions: boolean) => Promise<void>;
    onEditClick: (collectionId: string) => void;
}> = ({ collection, onDeleteClick, onEditClick }) => {
    const [deleteQuestions, setDeleteQuestions] = React.useState<boolean>(false);

    return (
        <Box
            display="grid"
            gridTemplateRows={['auto auto auto', null, 'auto auto']}
            gridTemplateColumns={['auto', '1fr auto']}
            gridGap={2}
            alignItems="center">
            <Box display="flex" gridGap={2} alignItems="center" row="1">
                {collection?.name}
                <Badge fontSize="10px" size="sm">
                    {collection?.questionCount} Questions
                </Badge>
                {!!collection?.orderEnforced && (
                    <Badge fontSize="10px" size="sm">
                        Order Enforced
                    </Badge>
                )}
            </Box>
            <Box gridRow="2">{collection?.tags && <ListTags tags={collection?.tags} />}</Box>
            <Box gridRow={['3', null, '1 / span 2']} gridColumn={['1', null, '2']} display="flex" gridGap={1}>
                <IconButton
                    size="sm"
                    icon={
                        <Icon>
                            <CommonFileTextEdit />
                        </Icon>
                    }
                    aria-label="edit collection"
                    title="Edit Collection"
                    onClick={() => onEditClick(collection?.id ?? '')}
                />
                <DeleteButtonWithPopover
                    onClose={() => setDeleteQuestions(false)}
                    title="Delete Collection"
                    popoverTitle="Delete Collection?"
                    popoverContent={() => (
                        <Checkbox
                            defaultChecked={false}
                            isChecked={deleteQuestions}
                            value="true"
                            id="orderEnforced"
                            onChange={(e) => {
                                setDeleteQuestions(e.target.checked);
                            }}>
                            Delete associated questions
                        </Checkbox>
                    )}
                    confirmText="Delete Collection"
                    onConfirm={async () => {
                        if (collection) {
                            await onDeleteClick?.(collection.id, deleteQuestions);
                        }
                    }}
                />
            </Box>
        </Box>
    );
};
